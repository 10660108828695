









































import { Vue, Component, Prop } from "vue-property-decorator";

@Component({
    data: () => ({
        miniVariant: false
    })
})
export default class HomeNavigationView extends Vue {
    private miniVariant: boolean;
    private footer: HTMLElement;
    private navigationDrawer: HTMLElement;

    public created() {
        this.$data.miniVariant = window.innerWidth < 1400;
        window.addEventListener("scroll", this.adjustPaddingBottom);
        window.addEventListener("resize", this.windowResized);
    }

    public mounted() {
        this.navigationDrawer = document.getElementById("navigation-drawer");
        this.footer = document.getElementById("footer");
    }

    public destroyed() {
        window.removeEventListener("scroll", this.adjustPaddingBottom);
        window.removeEventListener("resize", this.windowResized);
    }

    public windowResized() {
        this.$data.miniVariant = window.innerWidth < 1400;
        this.adjustPaddingBottom();
    }

    public adjustPaddingBottom() {
        const footerHeight = this.footer.clientHeight;
        const bodyHeight = document.body.clientHeight;
        const innerHeight = window.innerHeight;
        const scrollTop = document.body.scrollTop;
        var paddingBottom = 64 - (bodyHeight - innerHeight) + scrollTop;

        if (paddingBottom < 64 + footerHeight) {
            paddingBottom = paddingBottom + 58;
        }
        if (paddingBottom < 64) {
            paddingBottom = 64;
        }
        this.navigationDrawer.style.paddingBottom = paddingBottom + "px";
    }
}
