















import { Component, Vue, Watch } from "vue-property-decorator";
import { AlertDialogProperties } from "@/components/shared/AlertDialogView.vue";
import { Route } from "vue-router";
import HomeNavigationView from "./HomeNavigationView.vue";
import EventManager from "@/utilities/EventManager";
import ScreenUtil from "@/utilities/ScreenUtil";

const MainView = () => import("./main/MainView.vue");
const PaymentInfoView = () => import("./payment/PaymentInfoView.vue");
const PaymentListView = () => import("./payment/PaymentListView.vue");
const TransferInfoView = () => import("./transfer/TransferInfoView.vue");
const TransferListView = () => import("./transfer/TransferListView.vue");
const AlertDialogView = () => import("@/components/shared/AlertDialogView.vue");

class HomeProperties {
    events = new EventManager();
}

export { HomeProperties };

@Component({
    components: { HomeNavigationView, AlertDialogView },
    data: () => ({
        tab: null,
        navigationLeft: ""
    }),
    computed: {
        screenMedium: function() {
            return this.$vuetify.breakpoint.mdAndDown;
        }
    }
})
export default class HomeView extends Vue {
    private properties = new HomeProperties();
    private alertDialog = new AlertDialogProperties();
    private component: any;

    public async created() {
        this.$data.navigationLeft = window.innerWidth < 1400 ? 
            "margin-left:56px" : "margin-left:200px";

        if (ScreenUtil.screenMedium(this.$vuetify.breakpoint)) {
            const path = this.$route.fullPath;
            await this.$router.replace("/m" + path);
        } else {
            this.$router.beforeEach(async (to, from, next) => {
                await this.pathChanged(to);
                next();
            });

            this.pathChanged(this.$route);
            window.addEventListener("resize", this.windowResized);
        }
    }

    public destroyed() {
        window.removeEventListener("resize", this.windowResized);
    }

    public async windowResized() {
        this.$data.navigationLeft = window.innerWidth < 1400 ? 
            "margin-left:56px" : "margin-left:200px";

        if (ScreenUtil.screenMedium(this.$vuetify.breakpoint)) {
            window.removeEventListener("resize", this.windowResized);
            const path = this.$route.fullPath;
            await this.$router.replace("/m" + path);
        }
    }

    public async pathChanged(route: Route) {
        const path = route.path;
        const s = path.split('/');

        this.component = null;
        this.$forceUpdate();
        await Vue.nextTick();

        if (path === "/home") {
            this.component = MainView;
            this.$forceUpdate();
        } else if (path === "/payment" || s[1] === 'payment' ||
            path === "/processing" || s[1] === 'processing') {
            if (route.params.uniqueNo) {
                this.component = PaymentInfoView;
            } else {
                this.component = PaymentListView;
            }
            this.$forceUpdate();
        } else if (path === "/transfer" || s[1] === 'transfer') {
            if (route.params.uniqueNo) {
                this.component = TransferInfoView;
            } else {
                this.component = TransferListView;
            }
            this.$forceUpdate();
        }
    }

    public async alertDialogClicked(button: string) {
        this.alertDialog.visible = false;
        await this.properties.events.fire('alert-dialog-clicked', button);
        await this.alertDialogClosed();
    }

    public async alertDialogClosed() {
        if (this.alertDialog.visible) {
            return;
        }
        await this.properties.events.fire('alert-dialog-closed');

        const tag = this.alertDialog.tag;
        if (tag && tag.command === "load-error") {
            const redirect = tag.redirect;
            if (redirect) {
                await this.$router.push(redirect);
            }
        }
    }
}
